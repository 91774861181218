<template>
  <ModalLikes :idPost="news._id" nameEvent="likeNews" endpoint="/news/likes" @close="newsClose" />
</template>

<script>
import "./NewsVisor.scss";
export default {
  components: {
    ModalLikes: () => import("@/components/modals/ModalLikes.vue"),
  },
  data: function() {
    return {
      error: null,
    };
  },
  methods: {
    newsClose: function() {
      this.$store.state.news.NewsLikesVisor = null;
    },
  },
  computed: {
    news: function() {
      return this.$store.state.news.NewsLikesVisor || {};
    },
  },
};
</script>
